@use 'common/src/lib/style/settings' as vb;

.newsletter-dialog {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;

  @include vb.mq(md) {
    flex-direction: row;
    gap: 2rem;
  }
}

.newsletter-content {
  flex: 1 1 50%;

  @include vb.mq(md) {
    padding: 1rem 0 0 1rem;
  }
}

.newsletter-image {
  flex: 1 1 50%;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;

  @include vb.mq(md) {
    //max-height: none;
    max-height: 480px;
    //min-width: 450px;
    //width: 100%;
  }

  .ui-image img {
    border-radius: 12px;
    //max-height: 284px;
    //min-width: 100%;
    //object-fit: cover;

    @include vb.mq(md) {
      //max-height: none;
      //width: 100%;
    }
  }
}

.newsletter-close-button {
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  background-color: #fff;
}
